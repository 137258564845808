<template>
  <v-container fluid>
    <page-header title="優惠券">
      <template #rightSlot>
        <v-btn depressed color="primary accent-4" class="white--text" :to="{ name: 'CreatePromo' }">新增</v-btn>
      </template>
    </page-header>

    <datatable
      :tableHeaders="tableHeaders"
      :tableData="tableData"
      :page.sync="tablePage"
      :pageLimit.sync="tablePageLimit"
      :itemTotal="tableItemTotal"
      :isLoading="tableLoading"
      actionViewRouteLink="PromoDetail"
      @options-update="getList()"
    >
      <template #item.discount_type="{item}">
        <span v-if="item.discount_type === 'percent'">百分比</span>
        <span v-else-if="item.discount_type === 'amount'">固定金額</span>
      </template>
    </datatable>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import Datatable from '@/components/Datatable.vue'

export default {
  name: 'Promo',
  components: {
    Datatable,
  },
  data: () => ({
    tableLoading: false,
    tablePage: 1,
    tablePageLimit: 10,
    tableData: [],
    tableItemTotal: 0,

    // --- static data
    tableHeaders: [
      { text: '建立日期', value: 'create_date' },
      { text: '優惠碼', value: 'discount_code' },
      { text: '優惠券名稱', value: 'name' },
      { text: '優惠類型', value: 'discount_type' },
      { text: '指定帳戶', value: 'account' },
      { text: '可重用', value: 'reusable' },
      { text: '狀態', value: 'status' },
      { text: '', value: 'actionView', align: 'end' },
    ],
  }),
  methods: {
    ...mapActions(['setAlert']),
    getQueries() {
      const query = this.$route.query
      if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
        this.tablePage = parseInt(query['p'])
      }

      if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
        this.tablePageLimit = parseInt(query['l'])
      }
      this.getList(true)
    },

    _getData() {
      return new Promise(async resolve => {
        try {
          let payload = {
            limit: this.tablePageLimit,
            page: this.tablePage - 1,
          }

          const user = this.getCurrentUserData()
          const { data, total } = await this.$Fetcher.GetPromos(payload, user.id, user.token)
          this.tableItemTotal = total

          const list = data.map(el => {
            return {
              id: el.id,
              create_date: el.create_date,
              discount_code: el.discount_code,
              name: el.name,
              discount_type: el.discount_type,
              account: el.allow_all_user ? '全部' : '部分用戶',
              reusable: el.reusable ? '是' : '否',
              status: el.status,
            }
          })

          resolve(list)
        } catch (err) {
          this.$common.error(err)
          if (err) {
            this.$store.dispatch('toggleSnack', {
              type: 'error',
              message: err,
            })
          }
          resolve([])
        }
      })
    },

    async getList(pageInit = false) {
      if (!pageInit) {
        const newQuery = {
          p: this.$validate.regexNumber(this.tablePage) && parseInt(this.tablePage) > 1 ? this.tablePage : undefined,
          l:
            this.$validate.regexNumber(this.tablePageLimit) && parseInt(this.tablePageLimit) !== 10
              ? this.tablePageLimit
              : undefined,
        }

        const validNewQuery = this.$common.validNewQuery(this.$route.query, newQuery)
        if (validNewQuery) {
          this.$router.replace({ query: newQuery })
        }
      }

      this.tableLoading = true

      this._getData().then(list => {
        this.tableData = list
        this.tableLoading = false
      })
    },
  },

  mounted() {
    this.getQueries()
  },
}
</script>
